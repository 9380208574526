import { fetchGet, fetchPost, fetchPostPic, fetchGetMess } from "./index";
import type { CpoReason1st, CpoReason2nd } from "@/views/UserCar/types";

// CPO销售清单

//CPO销售清单删除
export const deleteBtn = (params: number | string): Promise<any> => {
  return fetchGet("/rvapi/ucMarketInfo/delete?id=" + params);
};
//表头统计查询
export const countTableSearch = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/ucMarketInfo/getTotal", params);
};
// 销售清单分页查询
export const getpageList = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/ucMarketInfo/listPage", params);
};
// 销售清单批量撤回
export const batchBack = (params = {}): Promise<any> => {
  console.log(params, "这是撤回参数");
  return fetchPost("/rvapi/ucMarketInfo/recall", params);
};
// 销售清单批量提交
export const batchSubmit = (params: any): Promise<any> => {
  return fetchPost("/rvapi/ucMarketInfo/submit", params);
};

// 销售清单文件上传
export const fileUpload = (params = {}, uploadConfig: object): Promise<any> => {
  return fetchPostPic("/rvapi/ucMarketInfo/uploadDoc", params, uploadConfig);
};
// 销售清单导入数据
export const ImportData = (params = {}, uploadConfig: object): Promise<any> => {
  return fetchPostPic("/rvapi/ucMarketInfo/uploadExcel", params, uploadConfig);
};

// 销售清单取消上传

export const cancelUpload = (params = {}): Promise<any> => {
  return fetchGet(
    "/rvapi/ucMarketInfo/cancelUpload?batchId=" + (params as any).batchId
  );
};

// 销售当前行文件查看按钮
export const viewFile = (params = {}): Promise<any> => {
  return fetchGet(
    "/rvapi/ucMarketInfo/preview?id=" +
      (params as any).id +
      "&type=" +
      (params as any).type
  );
};

// 操作记录接口
export const operationRecord = (params: any = {}): Promise<any> => {
  return fetchGet(`/rvapi/ucOperationInfo/findOperationInfo`, { params });
};
// 销售清单申诉接口
export const appealFileUp = (params = {}): Promise<any> => {
  return fetchPostPic("/rvapi/ucMarketInfo/appeal", params);
};

// 置换清单申诉接口
export const appealFileUpTradeIn = (params = {}): Promise<any> => {
  return fetchPostPic("/rvapi/ucReplacementInfo/appeal", params);
};

//判断申诉截止时间
export const checkForVerifyCPO = (params: any = {}): Promise<any> => {
  return fetchGet("/rvapi/ucMarketInfo/appeal/verify?id=" + params);
};
// 置换清单

//置换清单删除
export const deleteBtnTrande = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/ucReplacementInfo/delete?id=" + params);
};
// 置换清单导出查询结果按钮
export const exportResultTrande = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/ucMarketInfo/export", params);
};
//置换清单表头统计查询
export const countTableSearchTrande = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/ucReplacementInfo/getTotal", params);
};
// 置换清单分页查询
export const getpageListTrande = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/ucReplacementInfo/listPage", params);
};
// 置换清单批量撤回
export const batchBackTrande = (params = {}): Promise<any> => {
  console.log(params, "这是撤回参数");
  return fetchPost("/rvapi/ucReplacementInfo/recall", params);
};
// 置换清单批量提交
export const batchSubmitTrande = (params: any): Promise<any> => {
  return fetchPost("/rvapi/ucReplacementInfo/submit", params);
};

//置换清单文件下载
export const fileDownLoadTrande = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/ucReplacementInfo/templateDownload", params);
};
// 置换清单文件上传
export const fileUploadTrande = (
  params = {},
  uploadConfig: object
): Promise<any> => {
  return fetchPostPic(
    "/rvapi/ucReplacementInfo/uploadDoc",
    params,
    uploadConfig
  );
};
// 置换清单导入数据
export const ImportDataTrande = (
  params = {},
  uploadConfig: object
): Promise<any> => {
  return fetchPostPic(
    "/rvapi/ucReplacementInfo/uploadExcel",
    params,
    uploadConfig
  );
};

// 置换取消上传

export const cancelUploadTrande = (params = {}): Promise<any> => {
  return fetchGet(
    "/rvapi/ucReplacementInfo/cancelUpload?batchId=" + (params as any).batchId
  );
};

// 置换当前行文件查看按钮
export const viewFileTrande = (params = {}): Promise<any> => {
  return fetchGet(
    "/rvapi/ucReplacementInfo/preview?id=" +
      (params as any).id +
      "&type=" +
      (params as any).type
  );
};
//判断申诉截止时间
export const checkForVerifyTradeIn = (params: any = {}): Promise<any> => {
  return fetchGet("/rvapi/ucReplacementInfo/appeal/verify?id=" + params);
};

// 英文版
// CPO核查结果文件上传
export const checkResultUpload = (
  params = {},
  uploadConfig: object
): Promise<any> => {
  return fetchPostPic(
    "/rvapi/ucMarketInfo/batchUploadCheck/v2",
    params,
    uploadConfig
  );
};
// 置换核查结果文件上传
export const checkResultUploadTrande = (
  params = {},
  uploadConfig: object
): Promise<any> => {
  return fetchPostPic(
    "/rvapi/ucReplacementInfo/batchUploadCheck/V2",
    params,
    uploadConfig
  );
};
// 获取CPO  overView表头统计查询
export const getOverView = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/ucMarketInfo/getOverviewTotal", params);
};
// 获取置换  overView表头统计查询
export const getOverViewTrande = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/ucReplacementInfo/getOverviewTotal", params);
};

// 查询申诉信息cpo
export const appealRecord = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/ucMarketInfo/appealInfo?id=" + (params as any).id);
};

// 查询申诉信息置换
export const appealRecordTrande = (params = {}): Promise<any> => {
  return fetchGet(
    "/rvapi/ucReplacementInfo/appealInfo?id=" + (params as any).id
  );
};

// cpo发布与拒绝
export const batchRe = (params: any): Promise<any> => {
  return fetchPost(
    "/rvapi/ucMarketInfo/sipoCheck?status=" + params.status,
    params.ids
  );
};
// 置换发布与拒绝
export const batchReTrande = (params: any): Promise<any> => {
  return fetchPost(
    "/rvapi/ucReplacementInfo/sipoCheck?status=" + params.status,
    params.ids
  );
};

// cpo批量发布与拒绝
export const batchReAll = (params: any): Promise<any> => {
  return fetchPost(
    "/rvapi/ucMarketInfo/batchSipoCheck?status=" + params.status,
    params.data
  );
};
// 置换批量发布与拒绝
export const batchReTrandeAll = (params: any): Promise<any> => {
  return fetchPost(
    "/rvapi/ucReplacementInfo/sipoBatchCheck?status=" + params.status,
    params.data
  );
};

export const checkForBatchCPO = (params: any): Promise<any> => {
  return fetchPost(
    `/rvapi/ucMarketInfo/verifyStatus?status=${params.status}`,
    params.data
  );
};

export const checkForBatchTradeIn = (params: any): Promise<any> => {
  return fetchPost(
    `/rvapi/ucReplacementInfo/verifyStatus?status=${params.status}`,
    params.data
  );
};

// cpo核查按钮
export const checkRe = (params = {}): Promise<any> => {
  return fetchPostPic("/rvapi/ucMarketInfo/batchCheck", params);
};
export const ucMarketInfoBatchDelete = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/ucMarketInfo/batchDelete", params);
};
// 置换核查按钮
export const checkReTrande = (params = {}): Promise<any> => {
  return fetchPostPic("/rvapi/ucReplacementInfo/batchCheck", params);
};
export const ucReplacementInfoBatchDelete = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/ucReplacementInfo/batchDelete", params);
};
// cpo弹窗里 单条数据核查结果
export const checkSingle = (params: any): Promise<any> => {
  return fetchPost(
    "/rvapi/ucMarketInfo/checkResultOperation?id=" +
      params.id +
      "&checkResult=" +
      params.checkResult +
      "&msg=" +
      params.msg
  );
};
// 置换弹窗里 单条数据核查结果
export const checkSingleTrande = (params: any): Promise<any> => {
  return fetchPost(
    "/rvapi/ucReplacementInfo/checkResultOperation?id=" +
      params.id +
      "&checkResult=" +
      params.checkResult +
      "&msg=" +
      params.msg
  );
};

// 获取ocr识别列表
export const getOcrist = (params: any): Promise<any> => {
  return fetchGet(
    "/rvapi/ucOrcResult/listPage?batchId=" +
      params.batchId +
      "&current=" +
      params.current +
      "&size=" +
      params.size
  );
};

export const reasonList = (tagCn: string): Promise<CpoReason1st[]> => {
  return fetchGet("/rvapi/ucMarketInfo/cpo/reason/list", {
    params: {
      tagCn,
    },
  });
};

export const reasonDetailList = (
  checkReasonId: number
): Promise<CpoReason2nd[]> => {
  return fetchGet("/rvapi/ucMarketInfo/cpo/reasonDetail/list", {
    params: {
      checkReasonId,
    },
  });
};

export const cpoSalesFeedbackInfo = (id: number): Promise<string> => {
  return fetchGet(`/rvapi/ucMarketInfo/cpo/feedback/info/${id}`);
};

export const tradeInFeedbackInfo = (id: number): Promise<string> => {
  return fetchGet(`/rvapi/ucReplacementInfo/feedback/${id}`);
};
