
import { defineComponent, ref, watch, nextTick } from "vue";
import type { PropType } from "vue";
import { message } from "ant-design-vue";

export default defineComponent({
  emits: ["update:visible", "update:values"],
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    values: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    subTitle: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const input = ref();
    const inputContent = ref("");
    const inputValues = ref<string[]>([]);

    watch(
      () => props.visible,
      (visible) => {
        if (visible) {
          inputValues.value = props.values;
        }
      }
    );

    const handleInput = () => {
      if (inputContent.value.length < 5) {
        message.error("请从Excel中粘贴复制查询");
        inputContent.value = "";
        return;
      }
      inputValues.value = inputValues.value.concat(
        inputContent.value.split(/\s/).filter((item) => item !== "")
      );
      const dom = document.querySelector(".vin-list-box") as HTMLElement;
      inputContent.value = "";

      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };

    const handleClose = () => {
      emit("update:visible", false);
    };
    // 重置按钮
    const handleReset = () => {
      inputValues.value = [];
      input.value.focus();
    };

    const handleConfim = () => {
      emit("update:values", inputValues.value);
      handleClose();
    };

    return {
      input,
      inputContent,
      inputValues,
      handleInput,
      handleClose,
      handleReset,
      handleConfim,
    };
  },
});
